<template>
  <div class="page">
    <div class="wrapper illustration illustration_brackets">
      <Backlink title="questionnaire" service="mortgage" backlink="/mortgage/problems" />
      <div class="content">
        <AboutProblems service="mortgage" nextlink="/mortgage/delay" />
      </div>
    </div>
  </div>
</template>

<script>
import Backlink from '@/components/base/Backlink.vue'
import AboutProblems from '@/components/service/AboutProblems.vue'

export default {
  name: 'Mortgage_about_problems',
  components: { Backlink, AboutProblems }
}
</script>
